.ribbon {
    border-color: black;
  
    border-style: solid;
    border-width: 8px;
  
    border-bottom-color: transparent !important;
    border-top-width: 16px;
  
    height: 0;
    width: 0;
  }
  