.skip-link {
  position: absolute;
  top: -40px;
  left: 10px;
  background-color: #f1f1f1;
  color: #000;
  padding: 10px;
  z-index: 2;

  &:focus {
    color: #000;
    text-decoration: none;
    top: 10px;
  }
}

header.acpv-header {
  background-color: black;
  color: white;
  left: 50%;
  max-width: 2000px;
  position: fixed;
  top: 0;
  transform: translateX(-50%);
  width: 100%;
  z-index: 3;

  .acpv-nav {
    height: 4.375rem;
    margin: 0 auto;

    @include respond-to('md') {
      display: flex;
    }

    .logo-wrapper {
      position: relative;
      display: block;
      float: left;
      width: 200px;
      height: inherit;
      margin-left: 16px;

      @include respond-to('md') {
        width: 260px;
        margin-left: 30px;
        z-index: 1;
      }

      .logo {
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 200px;
        height: 11px;

        svg {
          width: 100%;
        }

        &:focus {
          outline: 2px solid #fff;
          outline-offset: 3px;
        }

        @include respond-to('md') {
          width: 260px;
          height: 14px;
        }
      }
    }

    .vert-spacer {
      @include respond-to('laptop+') {
        display: none;
      }

      hr {
        border-top: 2px solid #333;
        margin-inline: auto;
        margin-top: 40px;
        margin-bottom: 40px;
        padding: 0;
        width: 106px;
      }
    }

    .my-garage-cta {
      &:after {
        display: inline-block;
        content: "";
        width: 11px;
        height: 11px;
        margin-left: 0;
        position: relative;
        top: 1px;
        background: transparent url('/public/images/topnav/red-arrow.png') no-repeat;
      }
    }
    //logo-wrapper

    .links-group1-wrapper {
      height: inherit;
      position: relative;

      @media (max-width: 1023px) {
        display: none;
        float: none;
      }

      @media (min-width: 1024px) {
        flex: 1 0 auto;
      }

      .links-group1-content {
        @media (max-width: 1023px) {
          bottom: 0;
          display: flex;
          flex-direction: column;
          left: 0;
          overflow-y: auto;
          padding-top: 20px;
          position: fixed;
          top: 95px;
          width: 100%;
        }

        @media (min-width: 1024px) {
          align-items: center;
          display: flex;
          height: inherit;
          justify-content: center;
          position: relative;

          .links-primary {
            margin: 0 auto;
          }
        }
      }

      ul {
        display: block;
        width: 100%;

        @media (min-width: 1024px) {
          align-items: center;
          display: flex;
          height: inherit;
          justify-content: center;
          position: relative;
          width: auto;
        }

        li {
          font-family: $avenir-next-demi;

          @media (max-width: 1023px) {
            display: block;
            margin-bottom: 40px;
            position: relative;
            text-align: center;
          }

          // when nav item is active
          &.active a {
            @media (min-width: 1024px) {
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                top: 54px;
                left: 0;
                background-color: red;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }
          }

          a {
            color: white;
            padding: 20px;
            position: relative;
            text-decoration: none;

            //underline animation
            //http://tobiasahlin.com/blog/css-trick-animating-link-underlines/
            @media (min-width: 1024px) {
              display: block;
              font-size: 14px;
              line-height: 1;
              padding-inline: 8px;

              &:before {
                content: "";
                position: absolute;
                width: calc(100% - 26px);
                height: 2px;
                top: 54px;
                left: 0;
                right: 0;
                margin: 0 auto;
                background-color: red;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
              }

              &:hover:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }

            }

          }

          //a

          a:link, a:active, a:hover, a:visited {
            color: white;
            text-decoration: none;
            &:focus {
              outline: 2px solid #fff;
              outline-offset: 3px;
            }
          }

          // setting the last 2 <li>'s to smaller
          &.smaller {
            font-family: $avenir-next-regular;
            font-size: 14px;

            //hide on laptop
            @media (min-width: 1024px ) and (max-width: 1199px) {
              display: none;
            }
          }

          &.new-vehicles {
            a {
              line-height: 1.1;

              &:after {
                display: inline-block;
                content: "";
                width: 11px;
                height: 11px;
                margin-left: 5px;
                background: transparent url('/public/images/topnav/red-arrow.png') no-repeat;
              }
            }
          }
        }
      }
    }
    //links-group1-wrapper

    /**
    links-group2 is for laptop only. Contains 2 links, shown and hidden by hamburger
    */
    .links-group2-wrapper {
      position: relative;
      //show with script
      display: none;

      @include respond-to('laptop+') {
        background: #000;
        position: absolute;
        width: 100%;
      }

      ul {
        position: absolute;
        top: 115px;
        left: 0;
        width: 100%;

        li {
          font-family: $avenir-next-regular;
          font-size: 14px;

          @include respond-to('desktop-') {
            display: block;
            position: relative;
            text-align: center;
            margin-bottom: 40px;
          }

          button {
            border: none;
            background: none;
          }

          a,
          button {
            position: relative;
            color: white;
            text-decoration: none;
            padding: 20px;

            @include respond-to('desktop+') {
              line-height: 1;
              padding: 20px 13px 15px 13px;
              display: block;
              //underline animation
              //http://tobiasahlin.com/blog/css-trick-animating-link-underlines/
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 10px;
                left: 0;
                background-color: red;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
              }

              &:hover:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }

          }

          //a
          &:last-of-type {
            a {
              border-bottom: none;
            }

          }

          a:link, a:active, a:hover, a:visited {
            color: white;
            text-decoration: none;
          }

          // setting the last 2 <li>'s to smaller
          &.smaller {
            font-family: $avenir-next-regular;
            font-size: 14px;
          }

          &.new-vehicles {
            a {
              line-height: 1.1;

              &:after {
                display: inline-block;
                content: "";
                width: 11px;
                height: 11px;
                margin-left: 5px;
                background: transparent url('/public/images/topnav/red-arrow.png') no-repeat;
              }
            }
          }
        }
        //li
      }
      //ul
    }

    //links-group2-wrapper

    // My Account links within dropdown, shown until desktop+ (1200px)
    .links-group3-wrapper {
      position: relative;
      display: none;

      @include respond-to('laptop+') {
        position: absolute;
        width: 100%;
        background: #000;
      }

      ul {
        position: absolute;
        top: 115px;
        left: 0;
        width: 100%;

        li {
          font-family: $avenir-next-regular;

          &.item-header {
            font-family: $avenir-next-demi;
          }

          @include respond-to('desktop-') {
            display: block;
            position: relative;
            text-align: center;
            margin-bottom: 40px;
          }

          button {
            border: none;
            background: none;
          }

          a,
          button {
            position: relative;
            color: white;
            text-decoration: none;
            padding: 20px;

            @include respond-to('desktop+') {
              &:before {
                content: "";
                position: absolute;
                width: 100%;
                height: 2px;
                bottom: 10px;
                left: 0;
                background-color: red;
                visibility: hidden;
                -webkit-transform: scaleX(0);
                transform: scaleX(0);
                -webkit-transition: all 0.3s ease-in-out 0s;
                transition: all 0.3s ease-in-out 0s;
              }

              &:hover:before {
                visibility: visible;
                -webkit-transform: scaleX(1);
                transform: scaleX(1);
              }
            }
          }

          &:last-of-type {
            a {
              border-bottom: none;
            }
          }

          a:link, a:active, a:hover, a:visited {
            color: white;
            text-decoration: none;
          }
        }
      }

      .label-username,
      .item-my-orders,
      .item-my-appointments,
      .item-edit-profile,
      .item-log-out {
        display: none;
      }

      // show/hide based on authenticated
      &.authenticated {
        .label-my-account,
        .item-log-in {
          display: none;
        }

        .label-username,
        .item-my-orders,
        .item-my-appointments,
        .item-edit-profile,
        .item-log-out {
          display: block;
        }
      }
    }

    // My Account links within dropdown, hidden until desktop+ (1200px)
    .links-my-account {
      display: flex;
      justify-content: flex-end;
      padding-right: 50px;
      height: 100%;
      width: auto;

      @include respond-to(md) {
        padding-right: 63px;
        width: 100%;
      }

      @media (min-width: 1024px) {
        padding-right: 63px;
        width: auto;
      }

      @include respond-to(lg) {
        padding-right: 0;
      }

      .fav-count {
        color: #fff;
        display: none;
        font-family: $avenir-next-medium;
        font-size: 12px;
      }

      .fav-link {
        color: #fff;
        display: flex;
        align-items: center;
        gap: 1px;

        &:focus,
        &:hover {
          text-decoration: none;
        }

        &.has-count {
          .fav-icon {
            fill: #E82C2A;
            color: #E82C2A;
          }
          .fav-count {
            display: block;
            margin-top: 3px;
          }
        }
      }

      > ul {
        margin: 0 8px 0 0;
        display: flex;

        @include respond-to(lg) {
          margin-right: 17px; // 30px - 13px (from li padding)
        }

        > li {
          align-items: center;
          display: flex;
          border: none;
          height: 100%;

          > a,
          > button {
            color: #fff;
            line-height: 1;
            padding: 8px 10px;

            @include respond-to(lg) {
              color: #000;
              padding: 18px 13px 15px 13px;
            }
          }

          > button {
            background: transparent;
            border: none;
          }
        }

        > .my-account-icon-item {

          @include respond-to(lg) {
            display: none;
          }
        }

        > .my-account-dropdown-item {
          display: none;

          &.open {
            .dropdown-menu {
              display: block;
            }
            .caret {
              transform: rotate(180deg);
            }
          }

          .dropdown-toggle {
            color: #fff;
            font-family: $avenir-next-regular;
            font-size: 14px;
            line-height: 1.1;
            padding: 20px 13px 15px 13px;

            &:hover,
            &:focus {
              text-decoration: none;
            }

            &:focus {
              outline: -webkit-focus-ring-color auto;
            }
          }

          .dropdown-menu {
            display: none;
            left: auto;
            right: 0;
            border: none;
            padding: 7px 12px;
            margin: 0;
            width: 240px;
            box-shadow: 0px 4px 6px 0px #0000000D, 0px 10px 15px 0px #0000001A;
            background: #000;
            border-radius: 0;
            z-index: 3;

            > li {

              button {
                border: none;
                background: none;
                display: block;
                width: 100%;
                text-align: left;
              }

              a,
              button {
                color: #fff;
                padding: 10px;

                &:focus,
                &:hover {
                  color: #fff;
                  background: transparent;
                }
              }

              &.divider {
                margin: 10px;
                background-color: #4A4A4A;
              }
            }
          }

          @include respond-to(lg) {
            display: flex;
          }
        }
      }

      .caret {
        margin-bottom: 2px;
      }

      .label-username {
        display: none;
      }

      .item-my-orders,
      .item-my-appointments,
      .item-edit-profile,
      .item-log-out {
        display: none;
      }

      &.authenticated {
        .label-my-account {
          display: none;
        }

        .label-username {
          display: inline;
        }

        .item-log-in {
          display: none;
        }

        .item-my-orders,
        .item-my-appointments,
        .item-edit-profile,
        .item-log-out {
          display: block;
        }
      }
    }

    &.hide-links {
      .links-my-account {
        display: none;
      }
    }

    .hamburger-wrapper {
      bottom: 0;
      display: block;
      height: 33px;
      margin-bottom: auto;
      margin-right: 16px;
      margin-top: auto;
      position: absolute;
      right: 0;
      top: 0;
      width: $hamburger-white-width;
      z-index: 3;

      @include respond-to('md') {
        margin-bottom: auto;
        margin-right: 30px;
        margin-top: auto;
      }

      @include respond-to('lg') {
        display: none;
      }

      .hamburger {
        position: relative;
        height: 100%;
        width: 100%;
        cursor: pointer;

        .acpv-icon {
          width: 100%;
          height: 100%;
          border: none;

          .acpv-icon-bar {
            background-color: #fff;
            height: 2px;
            width: 31px;
            position: absolute;
            transition: all 200ms ease-in-out;
            display: block;

            &:nth-of-type(1) {
              transition: top 0.2s linear;
            }
          }

          .acpv-icon-txt {
            font-size: 10px;
            text-align: center;
            font-family: $avenir-next-bold;
            position: absolute;
            top: 0;
            left: 0;
          }

          &.on {
            border: #fff solid 1px;
            border-radius: 50%;
              animation: acpv-icon-animateOn cubic-bezier(0.18, 0.89, 0.32, 1.28) .3s;
              animation-delay: 0.3s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;

            .acpv-icon-txt {
              animation: acpv-icon-txt-animateOut linear 0.5s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
            }

            .acpv-icon-bar {
              height: 1px;
            }

            .acpv-icon-bar:nth-of-type(1) {
              top: 6px;
              opacity: 0;
            }

            .acpv-icon-bar:nth-of-type(2) {
              top: 15px;
                /*    transform: rotate(45deg);
                        transform-origin: 11px 6px;*/
            }

            .acpv-icon-bar:nth-of-type(3) {
              top: 15px;
           /*   transform: rotate(-90deg);  */   /*transform-origin: 43px 9px;*/
              animation: acpv-icon-third-animateOn linear 0.2s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
            }
          }

          &.off {
            .acpv-icon-txt {
              animation: acpv-icon-txt-animateIn linear 0.4s;
              animation-iteration-count: 1;
              transform-origin: 50% 50%;
              animation-fill-mode: forwards;
            }
          }

          .acpv-icon-bar:nth-of-type(1) {
            top: 2px;
            opacity: 1;
          }

          /*Mobile menu animation to X*/
          .acpv-icon-bar:nth-of-type(2) {
            top: 12px;
            transform: rotate(0);
            -webkit-transform: rotate(0);
          }

          .acpv-icon-bar:nth-of-type(3) {
            top: 22px;
            transform: rotate(0);
            -webkit-transform: rotate(0);
          }

          /*Mobile menu animation to X END*/
        }

        .acpv-icon.hamburger-white {
          position: absolute;
          width: $hamburger-white-width;
          height: $hamburger-white-height;
          top: 50%;
          margin-top: ($hamburger-white-height/2) * -1;
          left: 50%;
          margin-left: ($hamburger-white-width/2) * -1;
          cursor: pointer;
        }
        //acpv-icon
      }
    }
    //hamburger

    @keyframes acpv-icon-animateOn {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-45deg);
      }

    }
    @keyframes acpv-icon-third-animateOn {
      0% {
        transform: rotate(0deg);
      }

      100% {
        transform: rotate(-90deg);
      }

    }
    @keyframes acpv-icon-txt-animateOut {
      0% {
        opacity: 1;
        transform: scale(1);
      }

      100% {
        opacity: 0;
        transform: scale(2);
      }

    }

    @keyframes acpv-icon-txt-animateIn {
      0% {
        opacity: 0;
        transform: scale(2);
      }

      100% {
        opacity: 1;
        transform: scale(1);
      }
    }
    //hamburger-wrapper
  }
  //acpv-nav
}
//header.acpv-header
