footer.acpv-footer {
  background: #1b1e20;
  color: $footer-font-color;
  margin-top:10px;
  z-index: 1;
  max-width: 2000px;
  margin-inline: auto;

  @include respond-to(md) {
      margin-top:0px;
  }

  a:focus {
    outline-offset: 5px;
  }

  .ic {
    padding: 45px 0 160px 0;
  }
  .footer-row {
    text-align: center;

    @include respond-to('mobile') {
      padding: 0 20px;
    }
    @include respond-to('tablet') {
      // padding: 0 120px;
    }
    ul {
      li {
        display: inline-block;

        a:link, a:active, a:hover, a:visited {
          color: $footer-font-color;
          text-decoration: none;
          &:focus {
            outline:2px solid $footer-font-color;
            outline-offset: 3px;
          }
        }
        //a
      }
      //li
    }
    //ul

    &.footer-row1 {
      max-width: 520px;
      margin: 0 auto;

      @include respond-to("laptop+") {
        max-width: 730px;
      }

      li {
        color: $footer-font-color;
        font-family: $avenir-next-bold;
        font-size: 15px;
        line-height: 2em;
        margin-right: 0;
        padding-right: 8px;
        padding-left: 8px;

        &:last-of-type {
          margin-right: 0;
        }
      }
      //li

      padding-bottom: 40px;
    }
    //footer-row1

    &.footer-row2 {
      padding-bottom: 40px;

      .icon {
        margin-right: 16px;

        a {
          display: block;
          width: 38px;
          height: 38px;
          overflow: hidden;
          background-repeat: no-repeat;
          background-size: cover;

          img {
            width: 32px;
          }
        }
        &:last-of-type {
          margin-right: 0;
        }
        &.icon-twitter a {
          background-image: url(/images/footer/Twitter_Black-80.png);

          &:hover {
            background-image: url(/images/footer/Twitter_Black-100.png)
          }
        }
        &.icon-facebook a {
          background-image: url(/images/footer/Facebook_Black-80.png);

          &:hover {
            background-image: url(/images/footer/Facebook_Black-100.png);
          }
        }
        &.icon-instagram a {
          background-image: url(/images/footer/Instagram_Black-80.png);

          &:hover {
            background-image: url(/images/footer/Instagram_Black-100.png);
          }
        }
        &.icon-youtube a {
          background-image: url(/images/footer/YouTube_Black-80.png);

          &:hover {
            background-image: url(/images/footer/YouTube_Black-100.png);
          }
        }
        &.icon-googleplus a {
          background-image: url(/images/footer/Google+_Black-80.png);

          &:hover {
            background-image: url(/images/footer/Google+_Black-100.png);
          }
        }
        &.icon-pinterest a {
          background-image: url(/images/footer/Pinterest_Black-80.png);

          &:hover {
            background-image: url(/images/footer/Pinterest_Black-100.png);
          }
        }
      }
      //icon
    }
    //footer-row2

    &.footer-row3 {
      max-width: 550px;
      margin: 0 auto;

      @include respond-to("laptop+") {
        max-width: 980px;
      }

      li {
        color: $footer-font-color;
        font-family: $avenir-next-light;
        font-size: 10px;
        line-height: 2em;
        margin-right: 0;
        padding-right: 10px;
        padding-left: 10px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    }
    //footer-row3
  }
  //footer-row
    .cookie-policy-link {
      padding-top: 40px;
      max-width: 255px;
      margin: 0 auto;
      text-align: center;

      @include respond-to('sm') {
        max-width: 515px;
      }
      a {
        text-decoration: underline !important;
      }

      p {
        font-family: $avenir-next-light;
        line-height: 1.2;
        color: $footer-font-color;
        font-size: 12px;

        a {
          color: #fff;
        }
      }
    }
}
//header.acpv-header