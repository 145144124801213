body {

  font-family: $acura-bespoke-bold;

  //@todo comment these out when going to prod
  @include respond-to('mobile') {
    &:after {
      /*content:"mobile-XS";*/
      position: fixed;
      top: 0;
      left: 0;
      display: inline-block;
      padding:5px;
      font-size: 11px;
      background-color:red;
      color: white;
      z-index:9999;
    }
  }
  //@todo comment these out when going to prod
  @include respond-to('tablet') {
    &:after {
      /*content:"tablet-SM";*/
      position: fixed;
      top: 0;
      left: 0;
      display: inline-block;
      padding:5px;
      font-size: 11px;
      background-color:blue;
      color: white;
      z-index:9999;
    }
  }
  //@todo comment these out when going to prod
  @include respond-to('laptop') {
    &:after {
      /*content:"laptop-MD";*/
      position: fixed;
      top: 0;
      left: 0;
      display: inline-block;
      padding:5px;
      font-size: 11px;
      background-color:orange;
      color: white;
      z-index:9999;
    }
  }
  //@todo comment these out when going to prod
  @include respond-to('desktop+') {
    &:after {
      /*content:"desktop-LG";*/
      position: fixed;
      top: 0;
      left: 0;
      display: inline-block;
      padding:5px;
      font-size: 11px;
      background-color:#666;
      color: white;
      z-index:9999;
    }
  }

  @include respond-to("tablet+") {
    #atlwdg-trigger {
      z-index: 3;
    }
  }
}//body