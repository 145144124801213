$acpv-colors: (
    red: #f30000,
    black: #000000,
    white: #ffffff,
    deep-grey: #1e2123,
    dim-grey: #cccccc,
    grey: #797676,
    light-grey: #f3f3f3,
    accolade-navy: #293859,
    wire-blue: #004e9a
);

// convenience classes based on $bg-color map

// red
.bg-red {background-color: map_get($acpv-colors, red)}
.color-red {color: map_get($acpv-colors, red)};
@mixin bg-red {background-color: map_get($acpv-colors, red)}
@mixin color-red {color: map_get($acpv-colors, red)}

// black
.bg-black {background-color: map_get($acpv-colors, black)}
.color-black {color: map_get($acpv-colors, black)};
@mixin bg-black {background-color: map_get($acpv-colors, black)}
@mixin color-black {color: map_get($acpv-colors, black)}

// white
.bg-white {background-color: map_get($acpv-colors, white)}
.color-white {color: map_get($acpv-colors, white)};
@mixin bg-white {background-color: map_get($acpv-colors, white)}
@mixin color-white {color: map_get($acpv-colors, white)}

// deep-grey
.bg-deep-grey {background-color: map_get($acpv-colors, deep-grey)}
.color-deep-grey {color: map_get($acpv-colors, deep-grey)};
@mixin bg-deep-grey {background-color: map_get($acpv-colors, deep-grey)}
@mixin color-deep-grey {color: map_get($acpv-colors, deep-grey)}

// dim-grey
.bg-dim-grey {background-color: map_get($acpv-colors, dim-grey)}
.color-dim-grey {color: map_get($acpv-colors, dim-grey)};
@mixin bg-dim-grey {background-color: map_get($acpv-colors, dim-grey)}
@mixin color-dim-grey {color: map_get($acpv-colors, dim-grey)}

// grey
.bg-grey {background-color: map_get($acpv-colors, grey)}
.color-grey {color: map_get($acpv-colors, grey)};
@mixin bg-grey {background-color: map_get($acpv-colors, grey)}
@mixin color-grey {color: map_get($acpv-colors, grey)}

// grey
.bg-light-grey {background-color: map_get($acpv-colors, light-grey)}
.color-light-grey {color: map_get($acpv-colors, light-grey)};
@mixin bg-light-grey {background-color: map_get($acpv-colors, light-grey)}
@mixin color-light-grey {color: map_get($acpv-colors, light-grey)}

// accolade-navy
.bg-accolade-navy {background-color: map_get($acpv-colors, accolade-navy)}
.color-accolade-navy {color: map_get($acpv-colors, accolade-navy)};
@mixin bg-accolade-navy {background-color: map_get($acpv-colors, accolade-navy)}
@mixin color-accolade-navy {color: map_get($acpv-colors, accolade-navy)}

// wire-blue
.bg-wire-blue {background-color: map_get($acpv-colors, wire-blue)}
.color-wire-blue {color: map_get($acpv-colors, wire-blue)};
@mixin bg-wire-blue {background-color: map_get($acpv-colors, wire-blue)}
@mixin color-wire-blue {color: map_get($acpv-colors, wire-blue)}

$footer-font-color: #c6c6c6;