// General
@import "partials/_variables";
@import "partials/_mixins";
@import "partials/_reset";
@import "partials/_breakpoints";
@import "_sprite";
@import "_sprite-usage";
@import "partials/_global-containers";
@import "partials/_colors";
@import "/public/fonts/fonts.css";
@import "partials/_font-setup";
@import "partials/_buttons";
@import "partials/_form-elements";
@import "partials/_disclaimers";
@import "partials/_spinner";
@import "partials/_ribbon";

// Site
@import "partials/_base";
@import "partials/_header";
@import "partials/_footer";

// temp
@import "partials/_temp-panos";

// Google remarketing embeds an iFrame that has some height to it
// which creates some extra whitespace in our layout
iframe[name="google_conversion_frame"]{
  position:fixed;
}

.svg-sprite-sheet {
  height: 0;
  position: fixed;
  width: 0;
}

.svg-icon {
  height: 1em;
  width: 1em;
}
