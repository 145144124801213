/*
  hd:
  This will cycle through all sprites in _sprite.scss and create necessary classes
  There are instructions at the bottom of _sprite.scss which is generated by the module
*/
@include sprites($spritesheet-sprites);

/*
  hd:
  Assign this class as well as the individual classes that the module creates
  to see a list, look at _sprite.scss, it's the variable name with the long array argument list
  for each icon without the $

  for example:

  You'll find '$badge-grey-laptop'
  These should also match file names in the sprites directory (the individual pngs)

  to use:

  class="acpv-icon badge-grey-laptop"
*/
.acpv-icon {
  display: inline-block;
  overflow: hidden;
}