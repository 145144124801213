$breakpoints: (
        mobile: 0px,
        tablet: 768px,
        laptop: 992px, //laptop
        desktop: 1200px //desktop
        //desktoplg: 1440px
);

@mixin respond-to($breakpoint) {

  @if $breakpoint == "xs" {
    @media (max-width: 767px) {
      @content;
    }
  }

  @else if $breakpoint == "sm" {
    @media (min-width: 768px) {
      @content;
    }
  }

  @else if $breakpoint == "md" {
    @media (min-width: 992px) {
      @content;
    }
  }

  @else if $breakpoint == "lg" {
    @media (min-width: 1200px) {
      @content;
    }
  }
  
  @else if $breakpoint == "xl" {
    @media (min-width: 1440px) {
      @content;
    }
  }
  

    // +++
    // ++++++
    // +++++++++
    // ++++++++++++
    // below are more clear and expressive variable names and breakpoints
    // ++++++++++++
    // +++++++++
    // ++++++
    // +++

    // ---------------------------------------------------------

    // mobile
  @else if $breakpoint == "mobile" {
    @media (min-width: map-get($breakpoints, mobile)) and (max-width: map-get($breakpoints, tablet) - 1px) {
      @content;
    }
  }

    // mobile+
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('mobile+')
  @else if $breakpoint == "mobile+" {
    @media (min-width: map-get($breakpoints, mobile)) {
      @content;
    }
  }

    // ---------------------------------------------------------

    // tablet-
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('tablet-')
  @else if $breakpoint == "tablet-" {
    @media (max-width: map-get($breakpoints, laptop) - 1px) {
      @content;
    }
  }

    // tablet
  @else if $breakpoint == "tablet" {
    @media (min-width: map-get($breakpoints, tablet)) and (max-width: map-get($breakpoints, laptop) - 1px) {
      @content;
    }
  }

    // tablet+
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('tablet+')
  @else if $breakpoint == "tablet+" {
    @media (min-width: map-get($breakpoints, tablet)) {
      @content;
    }
  }

    // ---------------------------------------------------------

    // laptop-
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('laptop-')
  @else if $breakpoint == "laptop-" {
    @media (max-width: map-get($breakpoints, desktop) - 1px) {
      @content;
    }
  }

    // laptop
  @else if $breakpoint == "laptop" {
    @media (min-width: map-get($breakpoints, laptop)) and (max-width: map-get($breakpoints, desktop) - 1px) {
      @content;
    }
  }

    // laptop+
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('laptop+')
  @else if $breakpoint == "laptop+" {
    @media (min-width: map-get($breakpoints, laptop)) {
      @content;
    }
  }

    // ---------------------------------------------------------

    // desktop-
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('desktop-')
  @else if $breakpoint == "desktop-" {
    @media (max-width: map-get($breakpoints, desktop) - 1px) {
      @content;
    }
  }

    // desktop
  @else if $breakpoint == "desktop" {
    @media (min-width: map-get($breakpoints, desktop)) and (max-width: map-get($breakpoints, desktoplg) - 1px) {
      @content;
    }
  }

    // desktop+
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('desktop+')
  @else if $breakpoint == "desktop+" {
    @media (min-width: map-get($breakpoints, desktop)) {
      @content;
    }
  }

    // ---------------------------------------------------------
/*
    // desktoplg-
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('desktoplg-')
  @else if $breakpoint == "desktoplg-" {
    @media (max-width: map-get($breakpoints, desktoplg) - 1px) {
      @content;
    }
  }

    // desktoplg+
    // for usage, enclose in quotes with string contains symbols
    // so: respond-to('desktoplg+')
  @else if $breakpoint == "desktoplg+" {
    @media (min-width: map-get($breakpoints, desktoplg)) {
      @content;
    }
  }
*/
}//end mixin respond-to