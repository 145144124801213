@import "breakpoints";

/**

A mixin that will generate code with breakpoints for desktop, laptop, tablet and mobile and will assign
font-size to passed in values

- Each class also has an accompanying mixin so that you can easily @include the font treatment within your styles

- use-case 1: use a class on an element

  <div class='hl-1'></div>

- use-case 2: use an include within your sass

  .mystyle {
    @include hl-1
  }

*/
@import 'breakpoints';

@mixin responsive-font-sizes ($mobile, $tablet, $laptop, $desktop) {
  @include respond-to('mobile') {font-size: $mobile};
  @include respond-to('tablet') {font-size: $tablet};
  @include respond-to('laptop') {font-size: $laptop};
  @include respond-to('desktop+') {font-size: $desktop};
};

/**
* Fonts
**/
$font-stack: Arial, sans-serif;

$avenir-next-bold: 'AvenirNextBold', $font-stack;
$avenir-next-demi: 'AvenirNextDemi', $font-stack;
$avenir-next-medium: 'AvenirNextMedium', $font-stack;
$avenir-next-regular: 'AvenirNextRegular', $font-stack;
$avenir-next-light: 'AvenirNextLight', $font-stack;

$futura-extra-bold: 'FuturaNextCondensedExtraBold', $font-stack;

$acura-bespoke-light: 'AcuraBespokeLight', $font-stack;
$acura-bespoke-book: 'AcuraBespokeBook', $font-stack;
$acura-bespoke-regular: 'AcuraBespokeRegular', $font-stack;
$acura-bespoke-bold: 'AcuraBespokeBold', $font-stack;

.avenir-next-bold {font-family: $avenir-next-bold };
.avenir-next-demi {font-family: $avenir-next-demi };
.avenir-next-medium {font-family: $avenir-next-medium };
.avenir-next-regular {font-family: $avenir-next-regular };
.avenir-next-light {font-family: $avenir-next-light };

.futura-extra-bold {font-family: $futura-extra-bold };

.acura-bespoke-light {font-family: $acura-bespoke-light };
.acura-bespoke-book {font-family: $acura-bespoke-book };
.acura-bespoke-regular {font-family: $acura-bespoke-regular };
.acura-bespoke-bold {font-family: $acura-bespoke-bold };

/**
* Headline Fonts
*/

// arguments are sizes for:
// responsive-font-sizes(mobile, tablet, laptop, desktop);

@mixin hlf-3 {
  font-family: $futura-extra-bold;
  @include responsive-font-sizes(40px, 40px, 50px, 50px);
  text-transform: uppercase;
  letter-spacing: -2px; 
}
.hlf-3 {@include hlf-3}

@mixin hl-1 {
  font-family: $acura-bespoke-regular;
  @include responsive-font-sizes(35px, 55px, 55px, 55px);
}
.hl-1 {@include hl-1}

// ------------------------------------------------------------

@mixin hl-1a {
  font-family: $acura-bespoke-regular;
  @include responsive-font-sizes(30px, 45px, 50px, 50px);
}
.hl-1a {@include hl-1a}

// ------------------------------------------------------------

@mixin hl-2 {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(40px, 50px, 50px, 50px);
}
.hl-2 {@include hl-2}

// ------------------------------------------------------------

@mixin hl-2a {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(57px, 67px, 50px, 50px);
}
.hl-2a {@include hl-2a}

// ------------------------------------------------------------

@mixin hl-3 {
  font-family: $acura-bespoke-light;
  @include responsive-font-sizes(40px, 40px, 50px, 50px);
}
.hl-3 {@include hl-3}

// ------------------------------------------------------------

@mixin hl-3a {
  font-family: $acura-bespoke-light;
  @include responsive-font-sizes(25px, 30px, 50px, 50px);
}
.hl-3a {@include hl-3a}

// ------------------------------------------------------------

@mixin hl-4 {
  font-family: $avenir-next-light;
  @include responsive-font-sizes(25px, 30px, 36px, 36px);
}
.hl-4 {@include hl-4}

// ------------------------------------------------------------

@mixin hl-5 {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(20px, 32px, 32px, 32px);
}
.hl-5 {@include hl-5}

// ------------------------------------------------------------

@mixin hl-6 {
  font-family: $avenir-next-demi;
  @include responsive-font-sizes(30px, 30px, 30px, 30px);
}
.hl-6 {@include hl-6}

// ------------------------------------------------------------

@mixin hl-6a {
  font-family: $avenir-next-demi;
  @include responsive-font-sizes(25px, 40px, 30px, 30px);
}
.hl-6a {@include hl-6a}

// ------------------------------------------------------------

@mixin hl-7 {
  font-family: $acura-bespoke-regular;
  @include responsive-font-sizes(18px, 30px, 30px, 30px);
}
.hl-7 {@include hl-7}

// ------------------------------------------------------------

@mixin hl-8 {
  font-family: $acura-bespoke-regular;
  @include responsive-font-sizes(25px, 30px, 24px, 24px);
}
.hl-8 {@include hl-8}

// ------------------------------------------------------------

@mixin hl-9 {
  font-family: $avenir-next-demi;
  @include responsive-font-sizes(20px, 22px, 24px, 24px);
}
.hl-9 {@include hl-9}

// ------------------------------------------------------------

@mixin hl-10 {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(18px, 17px, 20px, 20px);
}
.hl-10 {@include hl-10}

// ------------------------------------------------------------

@mixin hl-10a {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(20px, 32px, 20px, 20px);
}
.hl-10a {@include hl-10a}

// ------------------------------------------------------------



/**
* Copy Fonts
*/

@mixin cp-1 {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(17px, 20px, 20px, 20px);
}
.cp-1 {@include cp-1}

// ------------------------------------------------------------

@mixin cp-2 {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(22px, 25px, 18px, 18px);
}
.cp-2 {@include cp-2}

// ------------------------------------------------------------

@mixin cp-3 {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(18px, 20px, 18px, 18px);
}
.cp-3 {@include cp-3}

// ------------------------------------------------------------

@mixin cp-4 {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(20px, 18px, 18px, 18px);
}
.cp-4 {@include cp-4}

// ------------------------------------------------------------

@mixin cp-5 {
  font-family: $avenir-next-light;
  @include responsive-font-sizes(16px, 16px, 18px, 18px);
}
.cp-5 {@include cp-5}

// ------------------------------------------------------------

@mixin cp-6 {
  font-family: $avenir-next-light;
  @include responsive-font-sizes(16px, 16px, 18px, 18px);
}
.cp-6 {@include cp-6}

// ------------------------------------------------------------

@mixin cp-7 {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(17px, 16px, 16px, 16px);
}
.cp-7 {@include cp-7}

// ------------------------------------------------------------

@mixin cp-8 {
  font-family: $avenir-next-light;
  @include responsive-font-sizes(16px, 16px, 18px, 18px);
}
.cp-8 {@include cp-8}

// ------------------------------------------------------------

@mixin cp-9 {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(14px, 14px, 16px, 16px);
}
.cp-9 {@include cp-9}

// ------------------------------------------------------------

@mixin cp-10 {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(18px, 30px, 14px, 14px);
}
.cp-10 {@include cp-10}

// ------------------------------------------------------------

@mixin cp-10a {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(18px, 25px, 14px, 14px);
}
.cp-10a {@include cp-10a}

// ------------------------------------------------------------

@mixin cp-11 {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(15px, 15px, 14px, 14px);
}
.cp-11 {@include cp-11}

// ------------------------------------------------------------

@mixin cp-11a {
  font-family: $avenir-next-bold;
  @include responsive-font-sizes(14px, 15px, 14px, 14px);
}
.cp-11a {@include cp-11a}

// ------------------------------------------------------------

@mixin cp-12 {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(15px, 16px, 14px, 14px);
}
.cp-12 {@include cp-12}

// ------------------------------------------------------------

@mixin cp-12a {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(15px, 23px, 14px, 14px);
}
.cp-12a {@include cp-12a}

// ------------------------------------------------------------

@mixin cp-13 {
  font-family: $avenir-next-medium;
  @include responsive-font-sizes(15px, 16px, 14px, 14px);
}
.cp-13 {@include cp-13}

// ------------------------------------------------------------

@mixin cp-14 {
  font-family: $acura-bespoke-light;
  @include responsive-font-sizes(16px, 18px, 14px, 14px);
}
.cp-14 {@include cp-14}

// ------------------------------------------------------------

@mixin cp-15 {
  font-family: $avenir-next-regular;
  @include responsive-font-sizes(14px, 12px, 12px, 12px);
}
.cp-15 {@include cp-15}