/* spinner-wrapper */
.spinner-wrapper {
  .spinner {

    display: none; 
    left: 50%;
    position: fixed;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 9999;

    &:before {
      animation: spin .75s linear infinite;
      border-radius: 50%;
      border-right: 2px solid transparent;
      border-top: 2px solid #fa0016;
      content: "";
      display: block;
      height: 70px;
      width: 70px;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg)
      }
      to {
        transform: rotate(1turn)
      }
    }
  }
}
/* spinner-wrapper End*/