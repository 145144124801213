/*

- oc: is the "outer container", this is a class for the wrapping element that will be edge to edge
- ic: is the "inner container", this class will center and have a max width of 1200 and adhere to other grid breakpoints

 */

.oc {
  position: relative;
  width: 100%;
}

.ic {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
}