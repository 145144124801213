.disclaimers {
  background-color: #293859;
  bottom: 0;
  box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
  left: 4%;
  margin: 0 auto;
  overflow: hidden;
  padding: 20px 35px;
  position: fixed;
  width: 92%;
  z-index: 3;
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s  ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  opacity: 0;
  -webkit-transform: translate(0, 80px);
  -moz-transform: translate(0, 80px);
  -o-transform: translate(0, 80px);
  -ms-transform: translate(0, 80px);
  transform: translate(0, 80px);
  display: none;

  @include respond-to(sm) {
    padding: 20px 40px;
  }
  @include respond-to(md) {
    padding: 20px 60px;
  }
  &.active {
    opacity: 1;
    -webkit-transform: translate(0, 0px);
    -moz-transform: translate(0, 0px);
    -o-transform: translate(0, 0px);
    -ms-transform: translate(0, 0px);
    transform: translate(0, 0px);
    display: block;
  }
  .disclaimers-content {
    color: #fff;
    text-align: center;
    line-height: 20px;

    a {
      color: white;
      text-decoration: underline;
    }
  }
  button.disclaimers-close {
    float: right;
    right: 10px;
    position: absolute;
    cursor: pointer;
    top: 10px;
    z-index: 10;
    padding:0;
    border: none;
    background-color: transparent !important;

    @include respond-to(sm) {
    }
    span {
      @include sprite($x-white-mobile);

      @include respond-to(sm) {
        @include sprite($x-white-laptop);
      }
      @include respond-to(md) {
        @include sprite($x-white-desktop);
      }
    }
  }
}
.disclaimer-callout {
  cursor: pointer;
  font-size: 18px;
  text-decoration: none !important;
  color:#000;

   @include respond-to(sm) {
    font-size: 20px;
  }
  @include respond-to(md) {
    font-size: 18px;
  }

  &:focus {
    outline:2px solid;
    outline-offset: 2px;
  }
}