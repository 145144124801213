
/**
button-1
button-1-fade
button-1-animate
*/

.button-1 {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  border:2px solid red;
  text-decoration: none!important;
  color: black!important;

  &:hover {
    @include bg-red;
    color: white!important;
  }

}//button-1

.button-1-fade {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  border:2px solid red;
  text-decoration: none!important;
  color: black!important;
  //background-color: white;
  background: transparent;

  // this is for reverse animation
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    @include bg-red;
    color: white!important;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

}//button-1-fade

.button-1-animate {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  border:2px solid red;
  padding: 15px 0;
  -webkit-transition: color 0.2s ease-in-out 0s;
  transition: color 0.2s ease-in-out 0s;

  @include cp-11a;

  text-decoration: none!important;
  color: black;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    @include bg-red;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    z-index: -1;
  }

  &:hover {
    color: white;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
    color: white;
  }

}//button-1 animate



/**
button-2
button-2-fade
button-2-animate
*/

.button-2 {

  position: relative;
  display: inline-block;
  // min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  text-decoration: none!important;
  color: white!important;
  @include bg-accolade-navy;

  &:hover {
    @include bg-wire-blue;
  }

  &.create-inv-alert {
    @include respond-to("mobile") {
      display: none;
    }
  }
}//button-2

.button-2-fade {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  text-decoration: none!important;
  color: white!important;
  @include bg-accolade-navy;

  // this is for the reverse animation
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    @include bg-wire-blue;
    color: white!important;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

}//button-2-fade

.button-2-animate {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  -webkit-transition: color 0.2s ease-in-out 0s;
  transition: color 0.2s ease-in-out 0s;
  @include bg-accolade-navy;
  text-decoration: none;
  color: white;

  span.title {
    @include cp-11a;
    position: relative;
    z-index:5;
  }

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    @include bg-wire-blue;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    z-index: 1;
  }

  &:hover {
    color: white;
  }

  &:hover:after {
    visibility: visible;
    width: 100%;
    color: white;
  }

}//button-2-animate



/**
button-3
button-3-fade
button-3-animate
*/

.button-3 {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  border:2px solid red;
  text-decoration: none!important;
  color: white!important;

  &:hover {
    @include bg-red;
    color: white!important;
  }

}//button-3

.button-3-fade {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  padding: 15px 0;
  // font
  @include cp-11a;
  border:2px solid red;
  text-decoration: none!important;
  color: white!important;
  //background-color: white;
  background: transparent;

  // this is for reverse animation
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;

  &:hover {
    @include bg-red;
    color: white!important;
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
  }

}//button-3-fade

.button-3-animate {

  position: relative;
  display: inline-block;
  min-width: 320px;
  text-align: center;
  border:2px solid red;
  padding: 15px 0;
  -webkit-transition: color 0.2s ease-in-out 0s;
  transition: color 0.2s ease-in-out 0s;

  @include cp-11a;

  text-decoration: none!important;
  color: white;

  &:before {
    content: "";
    position: absolute;
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    @include bg-red;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out 0s;
    transition: all 0.2s ease-in-out 0s;
    z-index: -1;
  }

  &:hover {
    color: white;
  }

  &:hover:before {
    visibility: visible;
    width: 100%;
    color: white;
  }

}//button-3 animate



