.temp-pano {

  background-size:cover;
  background-repeat: no-repeat;
  background-position: center center;

  @include respond-to('mobile'){
    width: 100%;
    padding-bottom: 61%;
    background-image: url(/images/test-panos/ML-Pano-992x606.jpg);
    background-position: center top;
    background-size:contain;
  }

  @include respond-to('tablet'){
    height: 606px;
    background-image: url(/images/test-panos/ML-Pano-992x606.jpg);
  }

  @include respond-to('laptop'){
    height: 566px;
    background-image: url(/images/test-panos/ML-Pano-1200x566.jpg);
  }

  @include respond-to('desktop+'){
    height: 700px;
    background-image: url(/images/test-panos/ML-Pano-1900x700.jpg);
  }

}//temp-pano